import { AuthService } from './common/service/auth.service';
import { ApiService } from 'src/app/common/service/api.service';
import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { environment } from 'src/environments/environment';
import { GlobalService } from './common/service/global.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private api: ApiService,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    this.splashScreen.show();
   
  }
  async ngOnInit(): Promise<void> {
    await this.initializeApp();
    this.globalService.loadAccounts();
  }

  async initializeApp() {
    const res = await this.api.get(environment.userAdmin + '/' + this.authService.getToken());
    environment.isAdmin = res.result;
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
